import journa1 from '../images/journa.jpg'
import Car from '../images/Car.JPG'
import Car1 from '../images/DSC00768.jpg'
import Car2 from '../images/DCD53EEE-D93D-4252-8BBA-328C1BB66DB7.jpg'

import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion'


export default function JournalisticGrid() {
    const images = [journa1, Car1, Car2/* Add more image URLs here */];
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);

    useEffect(() => {
        const handleEscape = (event) => {
          if (event.key === 'Escape') {
            handleClose();
          }
        };
    
        const handleClickOutside = (event) => {
            const overlay = document.querySelector('.image-grid-overlay');
            if (overlay && !overlay.contains(event.target)) {
              handleClose();
            }
          };
    
        document.addEventListener('keydown', handleEscape);
        document.addEventListener('click', handleClickOutside);
    
        return () => {
          document.removeEventListener('keydown', handleEscape);
          document.removeEventListener('click', handleClickOutside);
        };
      }, []);

    const handleClick = (image, index) => {
        setSelectedImageIndex(index);
    };

    const handleNext = (event) => {
        event.stopPropagation();
        setSelectedImageIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
      };
      
      const handlePrev = (event) => {
        event.stopPropagation();
        setSelectedImageIndex((prevIndex) =>
          prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
      };
      

    const handleClose = () => {
        setSelectedImageIndex(null);
    };

  return (
    <div>
    <div>
 <div className="ml-4 mr-4 grid grid-cols-2 md:grid-cols-2 gap-4">
<div className="grid gap-4">
         <AnimatePresence>
             {selectedImageIndex !== null && (
                 <motion.div
                     className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center z-50 bg-black"
                     onClick={handleClose}
                     initial={{ opacity: 0 }}
                     animate={{ opacity: 1 }}
                     exit={{ opacity: 0 }}
                 >
                     <motion.img
                         className="h-auto max-w-full rounded-lg"
                         src={images[selectedImageIndex]}
                         alt=""
                         initial={{ scale: 0 }}
                         animate={{ scale: 1 }}
                         exit={{ scale: 0 }}
                         transition={{ duration: 0.8 }} // Adjust the duration here (in seconds)
                         onClick={handleNext}
                         style={{ cursor: 'pointer' }}
                     />
                     <motion.div
                         className="absolute top-0 bottom-0 left-0 w-1/2"
                         onClick={handlePrev}
                         style={{ cursor: 'pointer' }}
                     />
                     <motion.div
                         className="absolute top-0 bottom-0 right-0 w-1/2"
                         onClick={handleNext}
                         style={{ cursor: 'pointer' }}
                     />
                 </motion.div>
             )}
         </AnimatePresence>
         <div>
<motion.img
       className="h-auto max-w-full rounded-lg hover:brightness-125"
       src={Car}
       alt=""
       whileHover={{ scale: 1.05 }}
       transition={{ duration: 0.5 }} // Adjust the duration here (in seconds)
       onClick={()=>handleClick(Car, 1)}
     />
</div>
</div>
<div className="grid gap-4">
<div>
<motion.img
       className="h-auto max-w-full rounded-lg hover:brightness-125"
       src={journa1}
       alt=""
       whileHover={{ scale: 1.05 }}
       transition={{ duration: 0.5 }} // Adjust the duration here (in seconds)
       onClick={()=>handleClick(journa1, 0)}
     />
</div>



</div>
<div>
<motion.img
       className="h-auto max-w-full rounded-lg hover:brightness-125"
       src={Car1}
       alt=""
       whileHover={{ scale: 1.05 }}
       transition={{ duration: 0.5 }} // Adjust the duration here (in seconds)
       onClick={()=>handleClick(Car1, 1)}
     />
</div>
<div>
<motion.img
       className="h-auto max-w-full rounded-lg hover:brightness-125"
       src={Car2}
       alt=""
       whileHover={{ scale: 1.05 }}
       transition={{ duration: 0.5 }} // Adjust the duration here (in seconds)
       onClick={()=>handleClick(Car2, 2)}
     />
</div>

</div>
</div>
</div>
)
}
